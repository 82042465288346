
import React from 'react';

import { config } from '@arandu/laravel-mui-admin';
import { useMediaQuery } from '@mui/material';

import { colors as colorsStatuses, icon as iconStatuses } from '../../../constants/TaskStatuses';
import { colors as colorsPriorities } from '../../../constants/TaskPriorities';
import { colors as colorsTypes, icon as iconTypes } from '../../../constants/TaskTypes';


export const useTaskTag = ({ task, showTags }) => {

    const taskStatuses = config('boot.data.constants.taskStatuses');
    const taskPriorities = config('boot.data.constants.taskPriorities');

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('md'));

    const showTag = {
        type: true,
        status: isTablet,
        project: true,
        priority: true,
        bugs: true,
        ...showTags,
    };

    const tags = React.useMemo(() => {

        const resolve = [];

        const { 
            type, 
            status, 
            priority, 
            bugs_detected, 
            recurring, 
            //
            project,
        } = task;

        /* * */

        const showInfectedTask = () => {

            const has_active_bug = bugs_detected.find((bug) => {
                return bug.status != 50;
            });

            if (has_active_bug) {
                resolve.push({
                    name: 'bug',
                    type: 'icon',
                    value: 'bug',
                    label: 'bugs.count',
                    labelSuffix: `${bugs_detected.length}`,
                    style: {
                        color: '#F60C0C',
                        backgroundColor: '#FFE8D5',
                    }
                });
            }
        };

        if (showTag.type) {
            if (bugs_detected.length > 0) {
                showInfectedTask();
            } else {
                /** Type */
                if (!!recurring === true) {
                    resolve.push({
                        name: 'type',
                        type: 'icon',
                        value: 'recurring',
                        label: `types.${type}`,
                        style: {
                            color: colorsTypes(type).color,
                            backgroundColor: colorsTypes(type).background,
                        }
                    });
                } else {
                    resolve.push({
                        name: 'type',
                        type: 'icon',
                        value: iconTypes(type),
                        label: `types.${type}`,
                        style: {
                            color: colorsTypes(type).color,
                            backgroundColor: colorsTypes(type).background,
                        }
                    });
                }
            }
        } else if (showTag.bugs && bugs_detected.length > 0) {
            showInfectedTask();
        }

        if (showTag.status) {
            /** Status */
            resolve.push({
                name: 'status',
                type: 'icon',
                value: iconStatuses(status),
                label: taskStatuses[status],
                style: {
                    color: colorsStatuses(status).color,
                    backgroundColor: colorsStatuses(status).background,
                }
            });
        }

        if (showTag.project && project) {

            const value = project.tag
                ? project.tag 
                : project.name.split(' ').reduce((acc, word) => {
                    acc += word.charAt(0).toUpperCase();
                    return acc;
                }, '');

            const label = project.tag
                ? project.tag
                : project.name;

            /** Project */
            resolve.push({
                name: 'project',
                type: 'text',
                value: value,
                label: label,
                style: {
                    color: '#191919',
                    backgroundColor: project.color ? project.color : '#7DBBDC',
                }
            });
        }

        if (showTag.priority) {
            /** Priority */
            if (priority === -1) {
                resolve.push({
                    name: 'priority',
                    type: 'icon',
                    value: 'block',
                    label: 'types.block',
                    style: {
                        color: colorsPriorities(priority).color,
                        backgroundColor: colorsPriorities(priority).background,
                    }
                });
            } else if (priority > 0) {
                resolve.push({
                    name: 'priority',
                    type: 'number',
                    value: priority,
                    label: taskPriorities[priority],
                    style: {
                        color: colorsPriorities(priority).color,
                        backgroundColor: colorsPriorities(priority).background,
                    }
                });
            }
        }

        return resolve;
    }, [task, task.bugs, task.bugs_detected]);

    return { tags };
};
