
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@arandu/laravel-mui-admin';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import { useTaskTag } from './TaskTag.hooks';


const TagBox = styled(Grid)(({ centered, boxStyle }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: centered ? 'center' : 'flex-start',
    gap: 6,
    ...boxStyle,
}));

export const ItemTag = ({ tag, ...tagProps }) => {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const {
        width = 30,
        height = 30,
        padding = .375,
        fontSize = 18,
        iconSize = 15,
        radius = 2,
    } = tagProps;

    const {
        color,
        backgroundColor,
    } = tag.style;

    const style = {
        minWidth: width, 
        height: height,
        padding: padding,
        color,
        fontSize: fontSize,
        radius,
        backgroundColor,
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let PopoverContent = 'tag';
    let PopoverLabel = '';

    switch (tag.name) {
    case 'project': {
        PopoverLabel = `'${tag.label}'`;
        break;
    }
    default: {
        PopoverLabel = tag.label;
        break;
    }
    }

    switch (tag.type) {
    case 'text':
    case 'number':        
        PopoverContent = `${t(`tag.${tag.name}`)} ${PopoverLabel}`;
        break;
    case 'icon':
        PopoverContent = t(`task.${PopoverLabel}`);
        break;
    }

    if (tag.labelSuffix) {
        PopoverContent += ` ${tag.labelSuffix}`;
    }

    const mountTagContent = () => {
        switch (tag.type) {
        case 'text':
        case 'number': return (
            <Typography>
                {tag.value}
            </Typography>
        );
        case 'icon': return (
            <Icon 
                name={tag.value} 
                width={iconSize}
                sx={{color: tag.style.color}}
            />
        );
        }
    };

    return (
        <Tag 
            key={tag.name}
            tagName={tag.name}
            //
            sx={style}
            //
            aria-owns={open ? `${tag.name}-mouse-over-popover` : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            {mountTagContent()}

            <Popover
                id={`${tag.name}-mouse-over-popover`}
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>
                    {PopoverContent}
                </Typography>
            </Popover>
        </Tag>
    );
};

const Tag = styled(Box)(({ tagName, sx }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //
    width: `${sx.width} !important`,
    height: sx.height,
    padding: tagName ===  'project' ? '0px 20px' : 6,
    //
    color: sx.color,
    borderRadius: sx.radius,
    backgroundColor: sx.background,
    //
    '& .MuiSvgIcon-root': {
        fontSize: sx.fontSize,
    },
    '& p': {
        fontSize: sx.fontSize,
        fontWeight: 500,
        lineHeight: '27px',
    },
}));

const TaskTag = ({ 
    task, 
    showTags, 
    centered = false, 
    ...props
}) => {

    const { tags } = useTaskTag({ task, showTags });

    const boxStyle = props?.boxStyle ?? {};
    const tagProps = props?.tagProps ?? {};

    return (
        <TagBox 
            boxStyle={boxStyle}
            centered={centered} 
        >
            {tags.map((tag) => (
                <ItemTag 
                    key={tag.name}
                    tag={tag}
                    {...tagProps}
                />
            ))}
        </TagBox>
    );
};

export default TaskTag;
